html {
	// font-size: 10px;
	height: 100%;
	font-family: 'El Messiri', sans-serif;

	body,
	#__next {
		height: 100%;
		margin: 0;
		direction: rtl;
	}
}
